<script>
import moment from 'moment'
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import DrawerFormModal from '../../../components/DrawerFormModal'
import { getAction } from '@/command/netTool'

export default {
  name: 'parkingLot',
  data() {
    return {
      ...api.command.getState(),
      showModal: false,
      detail: {
        dwCode: [],
        address: '',
        lat: '',
        lon: '',
        startTime: '00:00',
        endTime: '00:00',
        chargingPileState: '1',
      },
      areaCode: [],
      jwdAyy: [],
    }
  },
  mounted() {
    // 获取级联数据
    api.command.getRegionType.call(this, {
      url: '/api/region/threeTree',
      key: 'areaCode',
    })

    this.initList()
  },
  methods: {
    initList() {
      api.command.getList.call(this, {
        url: '/farmParkingLot/page',
        current: 1,
        paramsValue: {},
      })
    },
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input',
        },
        {
          label: '',
          type: 'text',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'mapUrl',
          title: '图片',
          align: 'left',
          customRender: (text, records) => {
            const src = records.mapUrl.split(',')[0]
            return (
              <div class="product-order-item-copy">
                <img src={src} />
              </div>
            )
          },
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          dataIndex: 'lon',
          title: '位置',
          align: 'left',
          customRender: (text, records) => {
            return `经度：${records.lon || '未知'} 纬度：${records.lat || '未知'}`
          },
          onExport: (text, records) => {
            return `经度：${records.lon || '未知'} 纬度：${records.lat || '未知'}`
          },
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => {
                  this.detail = records
                  this.detail.dwCode = [records.provinceCode, records.cityCode, records.areaCode]
                  this.detail.dw = `${records.lon}，${records.lat}`
                  this.showModal = true
                },
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除？',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/farmParkingLot/deleteBatch`,
                    params: {
                      idList: [records.id],
                    },
                  }),
              },
            ]
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.detail = {
              dwCode: [],
              address: '',
              lat: '',
              lon: '',
              startTime: '00:00',
              endTime: '00:00',
              chargingPileState: '1',
            }
            this.showModal = true
          },
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.del.call(this, {
                  url: `/farmParkingLot/deleteBatch`,
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
    getForm1() {
      return {
        title: '',
        type: 'cardForm',
        form: [
          {
            name: '名称(15字符内)',
            type: 'input',
            cols: 24,
            key: 'name',
            maxLength: 15,
            props: {
              maxLength: 15,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '营业开始时间',
            type: 'timePicker',
            cols: 6,
            key: 'startTime',
            props: {
              format: 'HH:mm',
              valueFormat: 'HH:mm',
              allowClear: false,
            },
          },
          {
            name: '营业结束时间',
            type: 'timePicker',
            cols: 6,
            key: 'endTime',
            props: {
              format: 'HH:mm',
              valueFormat: 'HH:mm',
              allowClear: false,
            },
          },
          {
            name: '是否配有充电桩',
            type: 'switch',
            key: 'chargingPileState',
            cols: 12,
          },
          {
            type: 'iconUpload',
            cols: 24,
            key: 'mapUrl',
            name: '地图图标',
            rules: [
              {
                required: true,
                message: '请上传地图图标',
              },
            ],
            typeData: [
              {
                maxLength: 1,
                key: 'mapUrl',
              },
            ],
          },
        ].filter((e) => (e.display == undefined ? true : e.display)),
      }
    },
    onOpenMap() {
      this.jwdAyy = []
      apiTool.openMap({
        title: '打开地图',
        value: this.jwdAyy,
        id: this.detail.id,
        onSuccess: (data) => {
          this.detail.lat = data.lat
          this.detail.lon = data.lng
          this.detail.dw = `${this.detail.lon}，${this.detail.lat}`
          let index = this.jwdAyy.findIndex((e) => e.id == data.id)
          let icon = new AMap.Icon({
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            imageSize: new AMap.Size(40, 40),
          })
          let obj = {
            lng: data.lng,
            lat: data.lat,
            icon: icon,
            id: data.id,
          }
          if (index > -1) {
            this.jwdAyy.splice(index, 1, obj)
          } else {
            this.jwdAyy.push(obj)
          }
          getAction('/api/gao', {
            location: `${this.detail.lon},${this.detail.lat}`,
          }).then((res) => {
            let { address, codes } = res.data
            this.detail.dwCode = codes
            this.detail.address = address
            this.$forceUpdate()
          })
          this.$forceUpdate()
        },
      })
    },
    getForm2() {
      return {
        title: '',
        type: 'cardForm',
        form: [
          {
            name: '经纬度 （例：120.184349，30.254460）',
            type: 'row',
            cols: 24,
            rules: [
              {
                required: true,
              },
            ],
            gutter: 5,
            children: [
              {
                type: 'input',
                cols: 18,
                key: 'dw',
                label: '经纬度',
                disabled: true,
                placeholder: '请选择经纬度',
                rules: [
                  {
                    required: true,
                    type: 'string',
                    message: '请选择经纬度',
                  },
                ],
              },
              {
                type: 'button',
                label: '定位',
                cols: 6,
                key: 'a1',
                props: {
                  type: 'primary',
                  icon: 'environment',
                  on: {
                    click: this.onOpenMap,
                  },
                },
              },
            ],
          },
          {
            name: '地址',
            type: 'row',
            cols: 24,
            rules: [
              {
                required: true,
              },
            ],
            gutter: 5,
            children: [
              {
                type: 'cascader',
                cols: 8,
                key: 'dwCode',
                label: '地址',
                typeData: this.areaCode,
                placeholder: '请选择地址',
                rules: [
                  {
                    required: true,
                    type: 'array',
                    message: '请选择地址',
                  },
                ],
              },
              {
                type: 'input',
                cols: 16,
                key: 'address',
                label: '地址',
                rules: [
                  {
                    required: true,
                    message: '请选择地址',
                  },
                ],
              },
            ],
          },
        ],
      }
    },
    getSumbitData() {
      const codeText = ['province', 'city', 'area']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = this.detail.dwCode[i]
      })
      return obj
    },
    onSubmit(data) {
      const codeData = this.getSumbitData()
      if (data.villageCode instanceof Array && data.villageCode.length == 1) {
        this.detail.villageCode = data.villageCode.toString()
      }
      let arr = data.dw.split('，')
      data.lon = arr[0]
      data.lat = arr[1]
      let obj = {
        ...data,
        ...codeData,
        regions: `${codeData.provinceName + codeData.cityName + codeData.areaName}`,
      }
      api.command[this.detail.id ? 'edit' : 'create']
        .call(this, {
          url: `/farmParkingLot/${this.detail.id ? 'update' : 'save'}`,
          params: {
            ...obj,
          },
          isPost: false,
        })
        .then(() => {
          this.showModal = false
          this.initList()
        })
    },
  },
  render() {
    return (
      <div>
        <TemplateHeaderTable
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          button={this.getButton()}
          records={this.records}
        />
        <DrawerFormModal
          visible={this.showModal}
          title="基本信息"
          width={'800px'}
          onClose={() => (this.showModal = false)}
          onSubmit={this.onSubmit}
          data={[this.getForm1(), this.getForm2()]}
          form={this.detail}
        ></DrawerFormModal>
      </div>
    )
  },
}
</script>
